import React from "react";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { StaticQuery, graphql } from "gatsby";
import "./style.scss";

const Footer = () => (
  <StaticQuery
    query={graphql`
      query SocialQuery {
        site {
          siteMetadata {
            youtube
            facebook
          }
        }
      }
    `}
    render={data => (
      <footer className="footer center has-background-light">
        <div className="content has-text-centered">
          <p className="is-size-4">The Armory - Tactical Gun Store</p>
          <p className="is-size-5">
            807 Airport Access Road STE 106, Traverse City, Michigan 49686, USA
          </p>
          <p className="is-size-5">
            <a href="tel:1-231-421-8233">(231) 421-8233</a> or{" "}
            <a href="mailto:jeff@armorytc.com">jeff@armorytc.com</a>
          </p>
          <article className="media center">
            <span className="icon">
              <a href={data.site.siteMetadata.facebook}>
                <FaFacebook height="fa-2x" width="fa-2x" color="blue" />
              </a>
            </span>
            &nbsp;
            <span className="icon">
              <a href={data.site.siteMetadata.youtube}>
                <FaYoutube color="red" height="fa-2x" width="fa-2x" />
              </a>
            </span>
            &nbsp;
          </article>
          &nbsp;
        </div>
      </footer>
    )}
  />
);

export default Footer;
