import React from "react";

import "./style.scss";

const Navbar = () => (
  <div className="hero-head is-hidden-mobile">
    <nav className="navbar">
      <div className="container" />
    </nav>
  </div>
);

export default Navbar;
