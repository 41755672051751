import React from "react";

import "./style.scss";

import armoryLogo from "../images/ArmoryTCLogo-Small.png";
import Navbar from "./navbar";

const Header = ({ siteTitle }) => (
  <section className="hero gradientBg is-small">
    <Navbar />
    <div className="hero-body">
      <div className="container center">
        <article className="media">
          <div className="media-content">
            <div className="content">
              <img src={armoryLogo} alt="The Armory" />
            </div>
          </div>
        </article>
      </div>
    </div>
  </section>
);

export default Header;
