import React from "react";

import "./style.scss";

const Midsection = () => (
  <div>
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="content">
              <p>Dear Customers and Friends of The Armory,</p>
              <p>
                I have somewhat bittersweet news for you all today. Starting on
                June 1st of this year, The Armory will begin liquidating it's
                inventory in preparation for permanently closing it's doors. But
                wait! It's not what you think! We are not bankrupt and have not
                run out of money. There is a reason for us choosing to go this
                path -
              </p>
              <p>
                Almost a decade ago I started The Armory for one reason and one
                reason alone - It was my opinion that the Traverse City area had
                very poor access to modern and tactical weapons and that I
                thought I could fulfill that need. I remember seeing guns like
                AK47's advertised in gun magazines and calling around to local
                shops and being told "Why would you want that? It's a terrorist
                gun!" or "We don't deal in those here." And so after careful
                planning, The Armory was born. And it took off like a rocket.
              </p>
              <p>
                Since then we've been on all kinds of wild adventures. I started
                a YouTube channel which became very successful, at least to me.
                We gave back to our community by donating money to local
                sheriff, fire department, and military charities as well as
                helped clean up the Hoosier valley range on a yearly basis. We
                saw Michigan law change during our business operations when
                handgun purchase permits were no longer required to buy a
                handgun from an FFL, or when SBR's became legal to own in
                Michigan. We are also proud to say that we handle all the
                Cerakote application for a big name in the gun industry: BCM.
              </p>
              <p>
                But as time goes on it has become obvious that the market share
                of Brick and Mortar gun shops is waning. More and more people
                are switching to buying their guns and ammo online - and I don't
                blame them! It's easy, it's often cheaper, and as technology
                evolves it will get more and more streamlined. So while The
                Armory is not doing poorly currently, financially speaking, it
                came to a point where we had to make a choice: Will we invest
                **heavily** to try and compete with the online gun marketplace,
                or move on to something else? It's taken almost a year or more
                for me to finally answer that question. But unfortunately, we're
                at a point where I've decided that investing in other projects
                is a better financial decision.
              </p>
              <p>
                So while closing The Armory is simply a business decision, I
                don't want it to downplay just how much I appreciate all of the
                customers and employees of The Armory that I've met along the
                way. I met some of the most stand-up type of people in my life
                while owning The Armory. Some of my life's fondest memories were
                made here. And I got to see some of the coolest guns I may ever
                see in my life, come through our doors. The Armory sold over
                10,000 firearms to mostly local residents during it's time and
                and about 5000 guns were serviced or Cerakoted here. We've
                coated guns for major manufacturers, had our guns featured on
                blogs and a magazine cover, and we even color-matched an AR15 to
                a country singer star's favorite car. I've met fans of our
                YouTube both locally and from as far away as Germany. No matter
                what career path I find myself on in the future, I can safely
                say that no matter how much money they make none of them will be
                as exciting as fun as The Armory was.
              </p>
              <p>
                And lastly, we leave our local customers in very capable hands.
                Lane from Ace Buyers, Dave at Great Guns, and the whole staff at
                Hampels and Shooters are all excellent people. One thing I pride
                myself in is not developing hostility among competitors, but
                rather working relationships that benefit eachother. If The
                Armory does leave any kind of void in the market, I'm sure they
                can easily take up the slack.
              </p>
              <p>
                Now, here are some important details regarding the process of
                closing:
              </p>
              <p>
                As we go through this process I want to make something
                abundantly clear: The reason we are closing is to persue new
                opportunities and NOT because we are going backrupt. It is
                extremely important to us that our customer's needs are
                fulfilled before we close up - even if we need to extend leases
                or licenses to do so! Here are the things that we will
                absolutely make sure are taken care of:
              </p>
              <ul>
                <li>
                  All consigned inventory will either be sold, paid for, or
                  picked up before we close our doors. NOBODY will be screwed
                  out of their gun or met with a "sorry we're closed" sign when
                  they come to get their gun or their check.
                </li>
                <li>
                  All pending NFA transactions will proceed as planned and WILL
                  NOT be cancelled or transferred to another dealer. We will
                  also be contacting the ATF and informing them of our plans and
                  they apparently will speed up the application process. NOBODY
                  will get screwed out of their suppressor, SBR, or machine gun.
                </li>
                <li>
                  Starting June 1st, all non-consignment inventory will be
                  subject to closeout pricing. All our customers will have an
                  opportunity to buy guns, ammo, and accessories for UNDER
                  dealer pricing.
                </li>
                <li>
                  While we have no idea how long the liquidation process will
                  take, we're hoping it will be completed by the end of the
                  year. As stated previously, we will keep our business location
                  retained for as long as it takes for NFA transactions to
                  clear. Initially, it's perfectly fine to keep your
                  consignments here but after a month of closeout sales I'll be
                  able to somewhat predict when a good time would be to start
                  calling people to have them pick up their consignments. We
                  will consult a lawyer on how to handle the event that a
                  consignee cannot be contacted or that they have died or
                  something. Again, I have no interest in stealing anyone's
                  stuff and we will do everything we can so that on the day we
                  close up for good, everyone has their stuff back that isn't
                  sold.
                </li>
                <li>
                  We will continue to take in gunsmithing and cerakote projects
                  at our normal rate until we have an idea of how long
                  liquidation will take. At some point we will stop taking in
                  service projects. That is to be determined.
                </li>
                <li>
                  Once a "stop date" is determined, any process that is ongoing
                  will no longer be available. This includes putting items on
                  consignment, having items coated or serviced, putting items on
                  layaway, etc.
                </li>
                <li>
                  Due to the length of processing times, no new NFA transactions
                  will be accepted after June 1st. All previous or pending NFA
                  transactions will continue as normal.
                </li>
              </ul>
              <p>
                If you have any questions or comments, please add them to the{" "}
                <a href="https://www.facebook.com/TheArmory/posts/2344790508911947?__tn__=K-R">
                  original facebook post
                </a>{" "}
                or contact us via one of the methods listed below.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default Midsection;
